import React from "react"

import Anchor from "../../components/anchor"
import Box from "../../components/box"
import Breadcrumb from "../../components/breadcrumb"
import Button from "../../components/button"
import Column from "../../components/column"
import Columns from "../../components/columns"
import Fuehrung from "../../content/shared/fuehrung"
import FuehrungenKontakt from "../../content/shared/fuehrungen-kontakt"
import Heading from "../../components/heading"
import Layout from "../../layouts/default"
import PageTitle from "../../components/page-title"
import Paragraph from "../../components/paragraph"
import Text from "../../components/text"
import Seo from "../../components/seo"
import Stack from "../../components/stack"

const linkStyles = {
  display: "block",
  py: 3,
  ":hover, :focus": {
    color: "primary",
  },
}

const IndexPage = () => {
  return (
    <Layout backdrop="workshops">
      <Seo
        title="Für Schulen"
        description="Verschiedene thematische Streifzüge durch den Turm, in dem Friedrich Hölderlin die zweite Hälfte seines Lebens verbracht hat, laden Schulklassen aller Jahrgangsstufen ein, den Dichter und seine Werken kennenzulernen."
      />

      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Für Schulen",
              link: "/fuer-schulen",
            },
            {
              title: "Angebote vor Ort",
              link: "/fuer-schulen/fuehrungen",
            },
          ]}
        />
        <Stack space={12}>
          <PageTitle>Angebote vor Ort</PageTitle>
          <Columns
            collapse={[true, true, false]}
            space={[6, 12]}
            reverse={true}
          >
            <Column width={["full", "full", "2/5"]}>
              <Box position={["static", "sticky"]} top={6}>
                <Stack>
                  <Stack space={3}>
                    <Text sans={true}>Springe zu:</Text>
                    <Stack space={0} dividers={true}>
                      <Text
                        as="a"
                        sans={true}
                        bold={true}
                        size={[2, 3]}
                        sx={linkStyles}
                        href="#grundschulklassen"
                      >
                        Grundschulklassen
                      </Text>
                      <Text
                        as="a"
                        sans={true}
                        bold={true}
                        size={[2, 3]}
                        sx={linkStyles}
                        href="#sekundarstufe-1"
                      >
                        Sekundarstufe I
                      </Text>
                      <Text
                        as="a"
                        sans={true}
                        bold={true}
                        size={[2, 3]}
                        sx={linkStyles}
                        href="#sekundarstufe-2"
                      >
                        Sekundarstufe II
                      </Text>
                      <Text
                        as="a"
                        sans={true}
                        bold={true}
                        size={[2, 3]}
                        sx={linkStyles}
                        href="#foerderung"
                      >
                        Förderung der Kreissparkasse Tübingen
                      </Text>
                    </Stack>
                  </Stack>
                  <Box display={["none", "none", "block"]}>
                    <FuehrungenKontakt forSchools={true} />
                  </Box>
                </Stack>
              </Box>
            </Column>
            <Column>
              <Stack space={[12, 24]}>
                <Stack space={[12, 24]}>
                  <Anchor id="vermittlung">
                    <Stack>
                      <Paragraph size={[2, 3, 4]}>
                        Verschiedene thematische Streifzüge durch den Turm, in
                        dem Friedrich Hölderlin die zweite Hälfte seines Lebens
                        verbracht hat, laden Schulklassen aller Jahrgangsstufen
                        ein, den Dichter und seine Werken kennenzulernen. Die
                        Angebote wurden auf die Bildungspläne des Landes
                        Baden-Württemberg abgestimmt und gemeinsam mit
                        ausgewählten Schulklassen evaluiert und
                        weiterentwickelt.
                      </Paragraph>
                      <Fuehrung
                        title="Angebot für Lehrerinnen und Lehrer"
                        description={`Bei einem Rundgang stellen wir Ihnen unser umfangreiches Angebot für Schulklassen vor und sprechen mit Ihnen über die Anschlussmöglichkeiten an den Schulunterricht.`}
                        duration="60 Minuten"
                        price="60 €"
                        bookable={true}
                        forSchools={true}
                      />
                    </Stack>
                  </Anchor>
                  <Anchor id="grundschulklassen">
                    <Stack space={[12, 24]}>
                      <Heading as="h2" level={2}>
                        Für Grundschulklassen
                      </Heading>
                      <Fuehrung
                        title="Hölderlins Dinge"
                        subtitle="Entdeckt die Räume des Hölderlinturms auf eigene Faust"
                        description={`In Kleingruppen macht ihr euch auf die Suche nach alten Dokumenten, schwer entzifferbaren Schriften und rätselhaften Fundstücken. Zeichnend erforscht ihr die Objekte in den Vitrinen und überlegt, was es damit auf sich haben könnte. Im anschließenden gemeinsamen Rundgang erfahrt ihr mehr über den Dichter Friedrich Hölderlin, seine Zeit in Tübingen, seinen Turm und die Dinge, die er hinterlassen hat.`}
                        features={[
                          "Schriften vergleichen",
                          "aus Texten gezielt Informationen entnehmen",
                          "Inhalte anhand von Stichwörtern wiedergeben",
                          "sachgerecht Meinungen begründen",
                        ]}
                        duration="60 Minuten"
                        price="60 €"
                        category="Ab Grundschulklasse 2"
                        bookable={true}
                        forSchools={true}
                      />
                      <Fuehrung
                        title="In der Haiku-Werkstatt"
                        subtitle="Sprecht, lauft und klatscht im Rhythmus von Hölderlins Gedichten"
                        description={`Für Hölderlin sind Worte wie Musik. Jedes Wort hat einen eigenen Klang und einen Rhythmus. Beim Schreiben spricht Hölderlin die Worte oft laut vor sich hin, geht mit ihnen spazieren oder klopft ihren Rhythmus auf den Tisch. Dabei achtet er genau auf die Zahl der Silben. In einem Rundgang durch die Ausstellung schauen wir uns das genauer an: Wir lesen, sprechen und klatschen Hölderlins Gedichte und gehen mit ihnen spazieren. Zuletzt bauen wir aus Silben-Bausteinen und Gedicht-Schablonen selbst kurze Haiku-Gedichte zusammen.`}
                        features={[
                          "Laut-Buchstabenbeziehungen herstellen",
                          "Wörter in Silben gliedern",
                          "handelnd mit Texten und Lyrik umgehen",
                          "Wörter sammeln und ordnen",
                        ]}
                        duration="90 Minuten"
                        price="80 €"
                        category="Ab Grundschulklasse 2"
                        bookable={true}
                        forSchools={true}
                      />
                      <Fuehrung
                        title="Fenstergedichte"
                        subtitle="Lasst euch von der Aussicht aus Hölderlins Fenstern inspirieren"
                        description={`Frühling, Sommer, Herbst und Winter: Während seiner Zeit im Turm beschreibt Friedrich Hölderlin immer wieder den Blick aus seinen Fenstern. Er beobachtet den Wechsel der Jahreszeiten in der Natur und lässt sich davon zu eigenen Gedichten inspirieren. Wir machen es ihm nach! In Form von Bildern und Versen bringen wir zu Papier, was sich vor den Fenstern des Hölderlinturms so alles abspielt.`}
                        features={[
                          "sprachliche und gestalterische Mittel und Schreibideen sammeln",
                          "nach Anregungen eigene Texte planen und schreiben",
                          "Wahrnehmungen und Empfindungen reflektieren",
                          "Produktion und Präsentation selbst konzipierter Ergebnisse",
                        ]}
                        duration="90 Minuten"
                        price="80 €"
                        category="Ab Grundschulklasse 3"
                        bookable={true}
                        forSchools={true}
                      />
                    </Stack>
                  </Anchor>
                  <Anchor id="sekundarstufe-1">
                    <Stack space={[12, 24]}>
                      <Heading level={2}>Sekundarstufe I</Heading>
                      <Fuehrung
                        title="Auf Hölderlins Fersen I"
                        subtitle="Entdeckt die Räume des Hölderlinturms auf eigene Faust"
                        description={`Bei einer Tour durch die Ausstellung begebt ihr euch auf Friedrich Hölderlins Fersen. In einer Einführung lernt ihr den Dichter kennen und erfahrt mehr über seine Lebensjahre im Tübinger Turm. Mit einem Suchauftrag erkundet ihr daraufhin eigenständig die Räume des Museums und die dortigen alten Schriften, Dokumente und Bilder. Ihr rätselt, zeichnet, fertigt Beschreibungen an und tauscht euch darüber aus. Dabei erfahrt ihr mehr über Hölderlins Leben und seine Literatur.`}
                        features={[
                          "Bezüge zwischen Text und Leben des Autors herstellen und dieses Wissen zum Textverstehen nutzen",
                          "Inhalte von Texten in eigenen Worten formulieren",
                          "Methoden der Texterschließung",
                          "Leseeindruck und Textverständnis erläutern",
                          "Fachbegriffe zur formalen Beschreibung von Texten verwenden",
                          "Texte zu Bildern und Bilder zu Texten gestalten ",
                        ]}
                        duration="60 Minuten"
                        price="60 €"
                        category="Ab Klassenstufe 5"
                        bookable={true}
                        forSchools={true}
                      />
                      <Fuehrung
                        title="Hölderlins junge Jahre im Tübinger Stift"
                        subtitle="Setzt euch mit den Träumen und Wünschen des jungen Friedrich Hölderlin auseinander"
                        description={`In Tübingen hat Friedrich Hölderlin nicht nur seine zweite Lebenshälfte verbracht. Er hat dort auch studiert. Ein Musterschüler war er allerdings nicht. In einer Einführung in Hölderlins wichtigste Lebensstationen, nähert ihr euch dem jungen Dichter mithilfe von Fragen und Aufgaben, die euch durch die Ausstellung leiten. Wie gut waren seine Zeugnisse? Mit wem war er befreundet? Und welche Pläne hatte er für seine Zukunft?`}
                        features={[
                          "Zugang zu Texten gewinnen und Methoden der Texterschließung anwenden",
                          "Themen von Texten benennen",
                          "Zusammenhänge zwischen Texten, ihrer Entstehungszeit sowie dem Leben des Autors erkennen",
                          "In produktiven Schreibformen dargestellte Lebenswelten mit der eigenen vergleichen",
                          "Historische Fragestellungen und Strategien zu deren Beantwortung entwickeln",
                          "Regionalgeschichtliche Beispiele in übergeordnete Zusammenhänge einordnen",
                        ]}
                        duration="60 Minuten"
                        price="60 €"
                        category="Ab Klassenstufe 5"
                        bookable={true}
                        forSchools={true}
                      />
                      <Fuehrung
                        title="Die versteckte Ordnung der Gedichte"
                        subtitle="Lernt kreative Ordnungsprinzipien von Gedichten kennen"
                        description={`Manche Gedichte sind nach Reimen geordnet, andere nach der Anzahl ihrer Silben und wieder andere nach dem Klang ihrer Wörter. In der Poesie ist alles eine Frage der Ordnung. Bei einem Rundgang durch die Ausstellung sehen wir uns Hölderlins Gedichte und ihre versteckten Ordnungen an. Im anschließenden Workshop puzzelt ihr ein durcheinander geratenes Gedicht nach verschiedenen Ordnungsprinzipien zusammen.`}
                        features={[
                          "Methoden der Texterschließung anwenden",
                          "Fachbegriffe zur formalen Beschreibung von Texten verwenden",
                          "die Wirkung von Gestaltungsmitteln erkennen, beschreiben und erläutern",
                          "Bezüge zwischen Text und Leben des Autors herstellen und dieses Wissen zum Textverstehen nutzen",
                          "Anwenden produktiver Schreibformen (Umschreiben und Ausgestalten von Texten)",
                        ]}
                        duration="90 Minuten"
                        price="80 €"
                        category="Ab Klassenstufe 7"
                        bookable={true}
                        forSchools={true}
                      />
                    </Stack>
                  </Anchor>
                  <Anchor id="sekundarstufe-2">
                    <Stack space={[12, 24]}>
                      <Heading level={2}>Sekundarstufe II</Heading>
                      <Fuehrung
                        title="Auf Hölderlins Fersen II"
                        subtitle="Entdeckt die Räume des Hölderlinturms auf eigene Faust"
                        description={`In einer Tour durch die Ausstellung erforscht ihr Zeugnisse zu Hölderlins Leben, seinem Werk und seiner Wirkungsgeschichte. Zum Auftakt werden Alltagszeugnisse aus Hölderlins Zeit im Turm und Stilmerkmale seiner Gedichte vorgestellt. In Kleingruppen geht es anschließend an eine eigenständige Erkundung der Ausstellungsräume. Abwechslungsreiche Arbeitsaufträge und Leitfragen regen zur Beschäftigung mit Themen und Objekten des Museums an. Dabei werden Charakterisierungen angefertigt, literarische Texte mit ihren Entstehungszeugnissen abgeglichen und eigene Thesen zu Hölderlins Leben und Werken formuliert.`}
                        features={[
                          "Themen und zentrale Aussagen eines Textes bestimmen und benennen",
                          "Leseeindruck und Textverständnis erläutern, begründen und sich damit auseinandersetzen",
                          "unterschiedliche Lesetechniken und Methoden der Texterschließung anwenden",
                          "komplexere Deutungen eines Textes formulieren",
                          "kultur- und literaturgeschichtliche Entwicklungen ins Textverständnis einbeziehen",
                          "Vorwissen, Fachwissen, Kontextwissen, Weltwissen und Leseerfahrung gezielt für das Textverstehen verwenden",
                          "zentrale Inhalte von Texten exzerpieren und textbezogen erläutern; dazu aussagekräftige Textbelege auswählen und zitieren",
                        ]}
                        duration="60 Minuten"
                        price="60 €"
                        category="ab Klassenstufe 10"
                        bookable={true}
                        forSchools={true}
                      />
                      <Fuehrung
                        title="Über Sehnsuchtsorte schreiben"
                        subtitle="Setzt euch mit Hölderlins Gedichten und euren eigenen Sehnsüchten auseinander"
                        description={`Für Hölderlin war Griechenland ein Ort, von dem er schon in seiner Schulzeit geträumt und den er doch sein ganzes Leben lang nie selbst bereist hat. Trotzdem (oder vielleicht gerade deshalb?) hat er ihn zum Schauplatz eines Romans und Sehnsuchtsort seiner Gedichte gemacht. Habt ihr auch so einen Sehnsuchtsort? Beim Gang durch die Ausstellung setzt ihr euch mit dem Dichter, seinen Träumen und Sehnsüchten auseinander und reflektiert eure eigenen Lebenserwartungen. Anknüpfend daran entstehen eigene kurze Texte.`}
                        features={[
                          "mit fremden Lebenswelten und -entwürfen auseinandersetzen und diese auf die eigene Lebenssituation übertragen",
                          "Formen bildlicher Ausdrucksweisen reflektieren",
                          "das eigene Sprachbewusstsein erweitern",
                          "Auseinandersetzung mit schwierigen Texten ",
                          "kreative Texte entwickeln und Verknüpfungen zur eigenen Lebenswelt schaffen",
                        ]}
                        duration="90 Minuten"
                        price="80 €"
                        category="ab Klassenstufe 10"
                        bookable={true}
                        forSchools={true}
                      />
                      <Fuehrung
                        title="Reiselyrik"
                        subtitle="Erfahrt mehr über Hölderlins bewegtes Leben und die Entstehung seiner Reisegedichte"
                        description={`Den in seinen Gedichten am häufigsten wiederkehrenden Sehnsuchtsort Griechenland hat Hölderlin nie mit eigenen Augen gesehen. Stattdessen unternahm er weite Fußreisen in die Schweiz und bis ins französische Bordeaux – im Winter, bei Eis und Schnee. Bei einem Gang durch die Ausstellungbeschäftigt ihr euch mit Zeugnissen seiner Reisen und gleicht sie mit den daraus hervorgegangenen Gedichten ab. Dabei rekonstruiert ihr, wie die reale Reise in die poetischen Texte einfließt, und erhaltet am Ende Anregungen für das eigene Schreiben.`}
                        features={[
                          "Wörter sammeln und ordnen",
                          "Aspekte literarischer Texte selbstständig entwickeln und in ihrem Wirkungsgefüge analysieren",
                          "die Wirkung eines Textes beschreiben und begründen (Textteile und Textganzes)",
                          "Textinhalte und Strukturen visualisieren",
                        ]}
                        duration="90 Minuten"
                        price="80 €"
                        category="ab Klassenstufe 11"
                        bookable={true}
                        forSchools={true}
                      />
                    </Stack>
                  </Anchor>
                  <Anchor id="foerderung">
                    <Stack>
                      <Heading as="h2" level={2}>
                        Förderung der Kreissparkasse Tübingen
                      </Heading>
                      <Box p={6} bg="muted">
                        <Stack space={6}>
                          <Heading as="span" level={5}>
                            Angebot für Schulen aus dem Landkreis Tübingen
                          </Heading>
                          <Paragraph size={[2, 3, 4]}>
                            Jede Schule im Landkreis Tübingen hat die
                            Möglichkeit, ein Museum im Landkreis Tübingen zu
                            besuchen. Diesen Besuch unterstützt die
                            Kreissparkasse Tübingen mit maximal 75 Euro pro
                            Besuch und bei Benutzung öffentlicher Verkehrsmittel
                            zusätzlich mit maximal 50 Euro je Klasse.
                          </Paragraph>
                          <Button
                            secondary
                            href="https://www.ksk-tuebingen.de/content/dam/myif/ksk-tuebingen/work/dokumente/pdf/x-pdf-ksk-tuebingen/schulfoerderung/museumsbesuch.pdf"
                          >
                            Mehr Informationen
                          </Button>
                        </Stack>
                      </Box>
                    </Stack>
                  </Anchor>
                  <Box display={["block", "block", "none"]}>
                    <FuehrungenKontakt forSchools={true} />
                  </Box>
                </Stack>
              </Stack>
            </Column>
          </Columns>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default IndexPage
